import React, { useState } from "react";
import Bee from "./Bee"
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import axios from "axios";

function ContactForm() {
  const [showMessage, setShowMessage] = useState(false)
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
    setShowMessage(true);
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://formspree.io/f/xleobdpk",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thank you for your enquiry. We will be in contact with you soon.", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };
  return (
    <form className="text-left" onSubmit={handleOnSubmit}>
      <label htmlFor="name" className="primary-text">
        Name:
      </label>
      <input type="text" id="name" name="name" className="form-control" required/>
      <br/>
      <label htmlFor="email" className="primary-text">
        Email:
      </label>
      <input type="email" id="email" name="email" className="form-control" required/>
      <br/>
      <label htmlFor="message" className="primary-text text-left">
        Message:
      </label>
      <textarea id="message" name="message" className="form-control" rows="3" required/>
      <div className="text-center mt-4 position-relative">
        <Bee/><Button variant="secondary" size="lg" type="submit" disabled={serverState.submitting}>Send</Button>
      </div>
      {serverState.status && (
        <Alert show={showMessage} variant={!serverState.status.ok ? "danger" : "success"} onClose={() => setShowMessage(false)} dismissible>
          {serverState.status.msg}
        </Alert>
      )}
      <input type="hidden" name="_subject" value="New Form Submission from Website." />
    </form>
  );
}

export default ContactForm;
